enum BreakPoint {
  mobileSP = "+320",
  mobileLP = "+375",
  mobileSL = "+480",
  mobileLL = "+568",
  tabletSP = "+600",
  tabletLP = "+768",
  tabletSL = "+960",
  tabletLL = "+1024",
  desktopXS = "+1280",
  desktopM = "+1380",
  desktopL = "+1440",
  desktopXL = "+1920",
  desktopXXL = "+2156"
}

enum TransitionSpeed {
  quick = "0.5s",
  quicker = "0.25s",
  quickest = "0.15s"
}

export type Color = "red" | "black" | "white" | "background";

export const theme = {
  colors: {
    black: "#000000",
    grey: "#666666",
    white: "#ffffff",
    offWhite: "#f1efed",
    background: "#e3dedb",
    red: "#C81E17"
  },
  breakpoints: BreakPoint,
  transitions: TransitionSpeed
};

export type Theme = typeof theme;