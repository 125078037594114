exports.components = {
  "component---src-pages-about-archive-tsx": () => import("./../../../src/pages/about/archive.tsx" /* webpackChunkName: "component---src-pages-about-archive-tsx" */),
  "component---src-pages-about-dennis-severs-tsx": () => import("./../../../src/pages/about/dennis-severs.tsx" /* webpackChunkName: "component---src-pages-about-dennis-severs-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-contact-find-us-tsx": () => import("./../../../src/pages/contact/find-us.tsx" /* webpackChunkName: "component---src-pages-contact-find-us-tsx" */),
  "component---src-pages-delivery-and-returns-tsx": () => import("./../../../src/pages/delivery-and-returns.tsx" /* webpackChunkName: "component---src-pages-delivery-and-returns-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-spring-clean-tsx": () => import("./../../../src/pages/spring-clean.tsx" /* webpackChunkName: "component---src-pages-spring-clean-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-book-page-tsx": () => import("./../../../src/templates/book-page.tsx" /* webpackChunkName: "component---src-templates-book-page-tsx" */)
}

